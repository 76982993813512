
// Fonts
// @import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "wm_variables";

// Bootstrap
// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

body{
  color: $wm_gray_dk;
}

.auth a{
  color: $wm_gray_dk
}
.auth-wrap{
  margin: 50px auto;
  padding: 15px;
  width: 350px;
}

#auth-logo{
  background-image: url('/img/wm-logo-600w.png');
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center center;
  height: 160px;
  margin-top: 0;
  position: relative;
}

#auth-logo p{
  bottom: 0;
  color: $wm_green;
  font-size: 2.1rem;
  font-weight: bold;
  margin: 0;
  position: absolute;
  text-align: center;
  width:100%;
}

.form-horizontal .form-group {
  margin: 0
}

.auth .panel-heading{
  background-color: $wm_logo_gold;
}

label{
  font-size: 1.6rem;
  margin-top: 10px;
  margin-bottom: 2px;
}

.auth .checkbox{
  padding: 0
}

.auth button {
  color: #f1f1f1;
  background-color: $wm_green;
  border: 3px solid $wm_green;
  margin-bottom: 15px;
  margin-top: 25px;

}

.auth button:hover {
  color: $wm_green;
  background-color: $wm_logo_gold;
}

.auth .forgot-pw{
  text-align: right;
  width: 100%
}