
$white: #ffffff;
$black: #333333;

$wm_green: #00693C;
$wm_gold: #FECB00;
$wm_logo_gold: #FFCC00;
$wm_gray_lt: #C6C6BC;
$wm_gray_dk: #57584F;

$wm_green_1: #7ABB00;
$wm_green_2: #024731;
$wm_green_3: #3C8A2E;
$wm_green_4: #BED600;

$bs_alert: #a94442;